<template>
    <div class="contenter" style="height:100%;padding-top:88px;padding-bottom:54px;">
        <Header style="margin-top:-88px;position: relative;"></Header>
        <div style="min-height:100%;width:100%;backgroup:#fdfdfd;">                     
            <div class="container conColor" style="min-height:auto;" v-show="showLoading==false">
                <ul class="side_Gourp cleft">
                    <li class="sideItem" v-for="(item,key) in sideList" :key="key">
                        <div class="titles" :class="sideActive==item.id?'current':''" @click="getGroupClick(item)">
                            <span :class="sideActive==item.id?'cheks':''"></span>
                            {{item.name}}
                        </div>
                    </li>
                </ul>
                
                
                <!-- 论文引用 -->
                <div class="viewrig cright" style="min-height:auto;">
                    <div class="pdg38">
                        <h2 class="pfg_title">
                            
                            <div class="dataSele cright">
                                <div class="seleName" @click.stop="getToggleList('province')">
                                    <span class="names">{{dateNameObj.name}}<i class="gwicon iconSelect" :class="showList.province?'':''"></i></span>
                                    <div class="seleList" v-show="showList.province">
                                        <p v-for="(v,k) in selectData" :key="k" @click="selectedProvinceObj(v)">{{v.name}}</p>
                                    </div>
                                </div>
                            </div>
                        </h2>
                    </div>

                    <div v-show="sideActive===1" style="min-height:480px;">
                        <ul class="listSide" style="min-height:480px;">
                            <li v-for="(v,k) in groupData5" :key="k">
                                <router-link :to="{path:'/Paperdetails',query:{id:v.autoid,tab:v.tab}}" target="_blank">
                                    <div class="title_one">
                                        <i class="gwicon lis"></i><span :title="'【'+v.Source+'】'+v.Title">【{{v.Source}}】{{v.Title}}</span>
                                    </div>
                                </router-link>
                            </li>
                        </ul>
                        <div class="ipages" :style="{'height':pageHeight+'px','line-height':pageHeight+'px'}" v-show="totalPage5>pageSize5">
                            <Page :current="currentPage5" :total="totalPage5" :page-size="pageSize5" @on-change="jumpPage5"/>
                        </div>
                    </div>
                    <div v-show="sideActive===2" style="min-height:480px;">
                        <ul class="listSide" style="min-height:480px;">
                            <li v-for="(v,k) in groupData6" :key="k">
                                <router-link :to="{path:'/Paperdetails',query:{id:v.autoid,tab:v.tab}}" target="_blank">
                                <div class="title_one">
                                    <i class="gwicon lis"></i><span :title="'【'+v.Source+'】'+v.Title">【{{v.Source}}】{{v.Title}}</span>
                                </div>
                                </router-link>
                            </li>
                        </ul>
                        <div class="ipages" :style="{'height':pageHeight+'px','line-height':pageHeight+'px'}" v-show="totalPage6>pageSize6">
                            <Page :current="currentPage6" :total="totalPage6" :page-size="pageSize6" @on-change="jumpPage6"/>
                        </div>
                    </div>
                </div>
                <div class="clear"></div>
            </div> 
            <div class="clear"></div>
        </div> 
        <Footer></Footer>
        <div class="loading" v-show="showLoading==true">            
            <div class="loadingInner"></div>
        </div>
    </div>
</template>
<script>
import Header from './commons/Header.vue';
import Footer from './commons/Footer.vue';
import axios from 'axios';
import {
  config,
  olapUrl,
} from "../until/common.js";
export default {
	name:'Paper',
	components:{
        Header,
        Footer,
    },
    data() {
        return {
            edp_url:null,
            showLoading:false,
            Page_Size:10,
            sideActive:1,
            sideList:[
                {id:1,name:'国内核心期刊'},
                {id:2,name:'外文期刊'},
            ],
            sideTab:[],
            showList:{},
            dateNameObj:{},
            selectData:[],
            //国内期刊引用
            groupData5:[],
            currentPage5:1,
            totalPage5:0,
            pageSize5:0,
            //外文期刊
            groupData6:[], 
            currentPage6:1,
            totalPage6:0,
            pageSize6:0,
            pageHeight:null,
        }
    },
    methods:{
        getToggleList:function(str) {
            if(this.showList[str]) {
                this.showList = {};
            } else {
                this.showList = {};
                this.showList[str] = true;
            }
            this.searchVal = '';
        },
        // 左侧菜单栏
        getGroupClick:function(item){
            this.sideActive = item.id;
            if(this.sideActive==1){
                this.getList5(1);
            }else{
                this.getList6(1);
            }
        },
        
        // 论文引用
        getList5(current){
            var that = this;
            that.showLoading = true;
            if(that.dateNameObj.id){
                axios.get(config.baseUrl+'api/comgedata?sp=JOURNAL_GETLIST&p=PageSize;PageIndex;year;lan&v='+this.Page_Size+';'+current+';'+this.dateNameObj.id+';ch').then((res)=> {  
                    res.data.result[0].forEach(function(v){
                        v.tab = 1;//tab为1时是中文期刊
                    });
                    that.groupData5 = res.data.result[0];
                    that.currentPage5 = res.data.result[1][0].PageIndex;
                    that.pageSize5 = res.data.result[1][0].PageSize;
                    that.totalPage5 = res.data.result[1][0].Total;
                    that.showLoading = false;
                }).catch(function(error) {
                    window.console.log(error);
                });
            }     
        },
        jumpPage5(current){
            this.getList5(current);
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        },
        // 外文期刊
        getList6(current){
            var that = this;
            that.showLoading = true;
            if(that.dateNameObj.id){
                axios.get(config.baseUrl+'api/comgedata?sp=JOURNAL_GETLIST&p=PageSize;PageIndex;year;lan&v='+this.Page_Size+';'+current+';'+this.dateNameObj.id+';en').then((res)=> {  
                    res.data.result[0].forEach(function(v){
                        v.tab = 2;//tab为2时是英文期刊
                    });
                    that.groupData6 = res.data.result[0];
                    that.currentPage6 = res.data.result[1][0].PageIndex;
                    that.pageSize6 = res.data.result[1][0].PageSize;
                    that.totalPage6 = res.data.result[1][0].Total;
                    that.showLoading = false;
                }).catch(function(error) {
                    window.console.log(error);
                });
            }  
        },
        jumpPage6(current){
            this.getList6(current);
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        },
        selectedProvinceObj:function(v){
            this.dateNameObj = v;
            if(this.sideActive==1){
                this.getList5(1);
            }else{
                this.getList6(1);
            }
        },
       
    },
    mounted() {
        this.edp_url = olapUrl;
        document.onclick = () => {
            this.showList = {};
        }

        let arr = [];
        // let date = new Date();
        // let year = date.getFullYear();
        let year = 2022;
        window.console.log(year,arr)
        for(let i=2016;i<=year;i++) {
            let obj = {id:i,name:i+'年'};
            arr.push(obj);
        }
        arr.reverse();
        this.selectData = [...arr];
        this.dateNameObj = this.selectData[0];
        if(this.$route.params.side) {
            this.sideActive = parseInt(this.$route.params.side);
        } else {
            this.sideActive = 1;
        }
        if(this.sideActive==1){
            this.getList5(1);
        }else if(this.sideActive==2){
            this.getList6(1);
        }

        var w_height = window.innerHeight;
        this.pageHeight = w_height-712;
    },
}
</script>
<style lang="scss">

</style>